<template lang="">
    <div>
        <div class="card dveb-white-card">
                <b-tabs content-class="mt-3 " class="nav nav-tabs nav-tabs-line">
                    <b-tab title="Kiracı Bilgileri">
                        <KiraciBilgileri />
                    </b-tab>
                    <b-tab title="Daire Bilgileri">
                        <DaireBilgileri />
                    </b-tab>
                  
                    <b-tab title="Belgeler ">
                        <Belgeler />
                    </b-tab>
                    <b-tab title="Konum Bilgisi ">
                        <Konum />
                    </b-tab>
                    <!-- <b-tab title="Belgeler">
                        <Belgeler />
                    </b-tab>
                    <b-tab title="Geçmiş">
                        <Gecmis />
                    </b-tab> -->
                </b-tabs>

            </div>
    </div>
</template>
<script>

export default {
    name:"property-detail",
 
    components:{
        KiraciBilgileri: () => import("@/components/kiracilar/detaylar/KiraciBilgileri"),
        DaireBilgileri:() => import("@/components/kiracilar/detaylar/DaireBilgileri"),
        // Resimler:() => import("@/components/kiracilar/detaylar/Resimler"),
        Belgeler:() => import("@/components/kiracilar/detaylar/Belgeler"),
        Konum:() => import("@/components/kiracilar/detaylar/Konum")
    },
   
    
}
</script>
<style lang="scss">
.nav-tabs {
    border-bottom: 0 !important;
    div {
        width: 100%;

        li {
            flex: 1;

            a {
                display: flex;
                justify-content: center;
                margin: 0 auto !important;
                font-size: 1rem;
                color: #102640 !important;
                border-bottom: 5px solid #102640 !important;

                &.active {
                    border-bottom: 5px solid #3F98FF !important;

                }
            }
        }
    }
}

.tab-content {
    width: 100%;
}

.tab-area {
    display: flex;
    flex-direction: column;

    .tab-content {
        display: flex;
        align-items: center;

        img {
            background-color: red;
            border-radius: 50%;
            margin-right: 3rem;
            border: 2px solid #3F98FF;
        }

        .content {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}</style>